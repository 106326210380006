%card {
  background-color: white;

  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.20);

  text-align: center;

  padding: 10px;
}

%full-screen-card-list {
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  overflow-y: scroll;

  background-color: $main-background;

  .Content {
    position: absolute;

    left: 50%;
    transform: translate(-50%, 0);

    width: 80%;
    max-width: 600px;

    h2 {
      font-size: 30px;
    }

    .Card {
      margin: 50px 0;
      @extend %card;
    }
  }
}
