@import url(https://fonts.googleapis.com/css?family=Arvo);
@import url(https://fonts.googleapis.com/css?family=Arvo);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Home .Content .button {
  display: block;
  color: #3498db;
  border: 2px solid #3498db;
  border-radius: 3px;
  padding: 5px 0;
  cursor: pointer;
  -webkit-transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear;
  transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear; }
  .Home .Content .button:hover {
    border-color: #25a25a;
    color: #25a25a; }
  .Home .Content .selected.button {
    border-color: transparent;
    background-color: #217dbb;
    color: white; }

.Home .Content .Card {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px; }

body {
  font-family: 'Arvo', -apple-system, sans-serif; }

.Home {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  background-color: #74b9ff; }
  .Home .Content {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 80%;
    max-width: 600px; }
    .Home .Content h2 {
      font-size: 30px; }
    .Home .Content .button {
      margin: 50px auto;
      font-size: 20px;
      max-width: 200px;
      text-decoration: none; }
    .Home .Content .Card {
      margin: 50px 0; }

.Quiz .button {
  display: block;
  color: #3498db;
  border: 2px solid #3498db;
  border-radius: 3px;
  padding: 5px 0;
  cursor: pointer;
  -webkit-transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear;
  transition: border-color 300ms linear, color 300ms linear, background-color 300ms linear; }
  .Quiz .button:hover {
    border-color: #25a25a;
    color: #25a25a; }
  .Quiz .selected.button {
    border-color: transparent;
    background-color: #217dbb;
    color: white; }

.Quiz .Content .Card {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px; }

.Quiz {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  background-color: #74b9ff; }
  .Quiz .Content {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 80%;
    max-width: 600px; }
    .Quiz .Content h2 {
      font-size: 30px; }
    .Quiz .Content .Card {
      margin: 50px 0; }

body {
  font-family: 'Arvo', -apple-system, sans-serif; }

.Quiz .grid {
  margin: 50px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center; }

.Quiz .button {
  font-size: 20px;
  width: 100%;
  min-width: 80px;
  max-width: 100px;
  margin: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: box-shadow 100ms linear;
  transition: box-shadow 100ms linear;
  text-decoration: none; }
  .Quiz .button:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 10px rgba(0, 0, 0, 0.24); }

.Quiz .Card {
  position: relative; }
  .Quiz .Card p.correct {
    color: #2ecc71; }
  .Quiz .Card p.wrong {
    color: #e74c3c; }
  .Quiz .Card .button.correct {
    background-color: #2ecc71;
    border-color: #2ecc71; }
  .Quiz .Card .button.wrong {
    background-color: #e74c3c;
    border-color: #e74c3c; }
  .Quiz .Card .listen {
    cursor: pointer; }

.Quiz .Card span.number {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 20px;
  opacity: 0.6; }

.Quiz .Card.end .button {
  max-width: 400px;
  width: 90%;
  margin: 10px auto; }

