@import "styles/_common.scss";

.Quiz {
  @extend %full-screen-card-list;

  .grid {
    margin: 50px auto;
    display: flex;

    flex-wrap: wrap;
    justify-content: center;
  }

  .button {
    @extend %button-common;

    font-size: 20px;

    width: 100%;
    min-width: 80px;
    max-width: 100px;

    margin: 10px 20px;

    box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 1px 2px rgba(0,0,0,0.24);

    transition: box-shadow 100ms linear;

    text-decoration: none;

    &:hover {
      box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 1px 10px rgba(0,0,0,0.24);
    }
  }

  .Card {
    position: relative;

    p.correct {
      color: #2ecc71;
    }

    p.wrong {
      color: #e74c3c;
    }

    .button.correct {
      background-color: #2ecc71;
      border-color: #2ecc71;
    }

    .button.wrong {
      background-color: #e74c3c;
      border-color: #e74c3c;
    }

    .listen {
      cursor: pointer;
    }
  }

  .Card span.number {
    position: absolute;
    top: 5px; left: 10px;

    font-size: 20px;
    opacity: 0.6;
  }


  .Card.end .button {
    max-width: 400px;
    width: 90%;

    margin: 10px auto;
  }
}
